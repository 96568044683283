import Observable from 'oneapp/src/classes/observable';
import CSRF from 'oneapp/src/utils/CSRF';
import StepsFactory from 'ran/classes/returnAuthorizationSteps/StepsFactory';

/**
 * Represents ReturnAuthorizationStepsMgr
 */
class ReturnAuthorizationStepsMgr extends Observable {
	/**
	 * @constructor
	 */
	constructor() {
		super();
		this.events = {
			storageGet: 'storageGet',
			storageUpdate: 'storageUpdate',
			storageRemove: 'storageRemove',
			submitReturnAuthorization: 'submitReturnAuthorization'
		};
		this.classes = {
			stepsLoader: 'js-ran-steps-loader',
			stepWrapper: 'js-ran-step-wrapper'
		};
	}

	/**
	 * Initialize return authorization steps manager
	 */
	initialize() {
		const wrapper = document.getElementsByClassName(this.classes.stepWrapper)[0];

		this.steps = [];
		this.continueURL = wrapper?.getAttribute('data-continue-url');
		this.requestData((data) => {
			this.data = data;
			this.orderNo = this.data.orderNo;
			this.hideStepsLoader();
			this.getStorageData(this.data.orderNo);
		});
	}

	/**
	 * Hide steps loader
	 */
	hideStepsLoader() {
		for (const el of Array.from(document.getElementsByClassName(this.classes.stepsLoader))) {
			el.remove();
		}
	}

	/**
	 * Notify about getting storage data
	 * @param {String} orderNo Order number
	 */
	getStorageData(orderNo) {
		this.notify(this.events.storageGet, {
			orderNo
		});
	}

	/**
	 * Initialize return authorization steps manager by stored data
	 * @param {Object} storedData
	 */
	initializeByStoredData(storedData) {
		this.storedData = storedData ? storedData : { steps: {} };
		this.initializeSteps();
	}

	/**
	 * Initialize Steps
	 */
	initializeSteps() {
		if (this?.data?.steps) {
			const stepsFactory = new StepsFactory();

			for (const [currentStepId, currentStep] of this.data.steps.entries()) {
				const step = stepsFactory.create(currentStep.stepName, {
					stepId: currentStepId,
					stepName: currentStep.stepName,
					stepWrapper: this.classes.stepWrapper,
					model: currentStep.model,
					global: this.data.global,
					storedData: this.storedData?.steps ? this.storedData.steps[currentStep.stepName] : null
				});

				step.subscribe(this);

				if (currentStep.dependentSteps) {
					const dependentSteps = currentStep.dependentSteps.map((dependentStepName) =>
						this.getStepByName(dependentStepName)
					);
					step.setDependentSteps(dependentSteps);
				}

				this.steps.push(step);
			}

			this.activateStep(this.getInitialActiveStepId());
		}
	}

	/**
	 * Returns initial active step id
	 * @returns {Number} Step id
	 */
	getInitialActiveStepId() {
		const notFilledStep = this.steps.find((step) => !step.isFilled());
		let penultStepId = this.steps.length - 2;

		if (!this.validateStepId(penultStepId)) {
			penultStepId = 0;
		}

		return notFilledStep ? notFilledStep.getStepId() : penultStepId;
	}

	/**
	 * Returns step by step name
	 * @param {String} stepName Step name
	 * @returns {ReturnAuthorizationStep} step
	 */
	getStepByName(stepName) {
		return this.steps.find((step) => step.getStepName() === stepName);
	}

	/**
	 * Process subscriptions notifications
	 * @param {String} updateID
	 * @param {Object} data
	 */
	update(updateID, data) {
		switch (updateID) {
			case 'activatePreviousStep':
				this.activatePreviousStep();
				break;
			case 'activateSelectedStep':
				this.activateStep(parseInt(data.stepId));
				break;
			case 'submitReturnAuthorization':
				this.notify(this.events.submitReturnAuthorization, { orderNo: this.orderNo });
				break;
			case 'submitStep':
				this.submitStep(data);
				break;
			case 'renderStep':
				this.updateStorage(data);
				break;
			default:
				break;
		}
	}

	/**
	 * Update storage data
	 * @param {Object} data
	 */
	updateStorage(data) {
		const payload = { orderNo: this.orderNo, steps: {} };
		payload.steps[data.requestData.stepName] = data.storageData;

		this.notify(this.events.storageUpdate, payload);
	}

	/**
	 * Check if step id is correct
	 * @param {Number} stepId
	 * @returns {Boolean} Is valid
	 */
	validateStepId(stepId) {
		return stepId >= 0 && stepId < this.steps.length;
	}

	/**
	 * Returns active step
	 * @returns {ReturnAuthorizationStep} active step
	 */
	getActiveStep() {
		return this.activeStep;
	}

	/**
	 * Activate step by step id
	 * @param {Number} stepId
	 */
	activateStep(stepId) {
		if (this.validateStepId(stepId)) {
			this.activeStep = this.steps[stepId];
			this.activeStep.render();

			window.scrollTo({
				top: 0,
				left: 0,
				behavior: 'smooth'
			});
		}
	}

	/**
	 * Activate next step
	 */
	activateNextStep() {
		const currentStep = this.getActiveStep();
		var nextStepId = currentStep.getStepId() + 1;
		this.activateStep(nextStepId);
	}

	/**
	 * Activate previous step
	 */
	activatePreviousStep() {
		const currentStep = this.getActiveStep();
		var nextStepId = currentStep.getStepId() - 1;
		this.activateStep(nextStepId);
	}

	/**
	 * Submit step
	 * @param {Object} data
	 * @returns {Promise}
	 */
	submitStep(data) {
		const formData = data.requestData.formData || new FormData();
		formData.append(this.data.formFields.actions.submitStep.htmlName, data.requestData.stepName);

		return this.requestData(function(result) {
			if (result.success) {
				this.updateStorage(data);
				this.activateNextStep();
			} else {
				this.getActiveStep().setErrorMessage(result);
			}
		}, formData);
	}

	/**
	 * Submit return authorization
	 * @param {FormData} formData
	 * @returns {Promise}
	 */
	submitReturnAuthorization(formData = new FormData()) {
		if (this.data?.formFields?.serviceFields?.acceptPolicy) {
			formData.append(this.data.formFields.serviceFields.acceptPolicy.htmlName, true);
		}

		formData.append(this.data.formFields.actions.submitReturnAuthorization.htmlName, true);

		return this.requestData((result) => {
			if (result.success) {
				this.notify(this.events.storageRemove, { orderNo: this.orderNo });
				this.activateNextStep();
			} else {
				const stepWithError = this.getStepByName(result.stepName);

				this.activateStep(stepWithError.getStepId());
				this.getActiveStep().setErrorMessage(result);
			}
		}, formData);
	}

	/**
	 * Request data to BE
	 * @param {Function} callback Callback function
	 * @param {FormData} formData From data
	 * @returns {Promise}
	 */
	 requestData(callback, formData = new FormData()) {
		CSRF.populatePayloadByCSRFToken(formData)
			.then((formData) => fetch(
				app.util.appendParamsToUrl(this.continueURL, {
					format: 'json'
				}),
				{
					method: 'POST',
					body: formData
				}
			))
			.then((response) => {
				return response.json();
			})
			.then((responseJson) => {
				if (responseJson.redirectURL) {
					app.page.redirect(responseJson.redirectURL)
				} else {
					this.continueURL = responseJson.continueURL;

					if (callback) {
						callback.call(this, responseJson.model);
					}
				}
			})
			.catch(() => {
				window.location.href = this.continueURL;
			});
	}
}

export default ReturnAuthorizationStepsMgr;
