import ProductTileBuilder from '../productTile/Builder';
import ProductTileDirector from '../productTile/Director';
import ProductTile from 'oneapp/src/classes/productTile/ProductTile';
import FormFieldFactory from 'oneapp/src/utils/FormFieldFactory';

/**
 * Represents return authorization step markup builder
 */
class Builder {
	/**
	 * @constructor
	 * @param {String} stepName
	 * @param {Object} stepModel
	 * @param {Object} globalData
	 */
	constructor(stepName, stepModel, globalData) {
		this.stepName = stepName;
		this.stepModel = stepModel;
		this.globalData = globalData;
	}

	/**
	 * Build progress widget block
	 */
	buildProgressWidgetBlock() {
		let progressWidgetBlocks = '';

		for (const step of this.globalData.progressWidget.steps) {
			progressWidgetBlocks += app.util.renderTemplate(document.getElementById('ranProgressWidgetSectionBlock').innerHTML, {
				sectionHeader: step.label,
				customCssClass: step.stepName === this.stepName ? 'active' : ''
			});
		}

		this.stepModel.progressWidgetBlock = app.util.renderTemplate(
			document.getElementById('ranProgressWidgetBlock').innerHTML,
			{
				progressWidgetBlocks,
				length: this.globalData.progressWidget.steps.length
			}
		);
	}

	/**
	 * Build come to back block
	 */
	buildComeToBackBlock() {
		const comeToBackButton = this.stepModel?.stepData?.comeToBackButton;

		if (comeToBackButton) {
			this.stepModel.comeToBackBlock = app.util.renderTemplate(
				document.getElementById('ranComeToBackBlock').innerHTML,
				comeToBackButton
			);

			if (comeToBackButton.url) {
				this.stepModel.comeToBackBlock = app.util.renderTemplate(
					document.getElementById('ranLinkComeToBackBlock').innerHTML,
					{ comeToBackBlock: this.stepModel.comeToBackBlock, href: comeToBackButton.url }
				);
			}
		}
	}

	/**
	 * Build come to back block
	 */
	buildRefundMethodBlock() {
		if (this.stepModel.refundMethodRadio) {
			for (const option of this.stepModel.refundMethodRadio.options) {
				option.label = this.buildRefundMethodLabelBlock({
					label: option.label,
					additionalInfo: option.additionalInfo
				});
			}

			const formFieldFactory = new FormFieldFactory();
			const refundMethodsBlock = formFieldFactory.create(this.stepModel.refundMethodRadio);
			const refundMethodDataBlock = this.constructor.buildRefundMethodDataBlock(this.stepModel.selectedRefundMethod);

			this.stepModel.formContent = app.util.renderTemplate(document.getElementById('ranRefundMethodBlock').innerHTML, {
				refundMethodsBlock: refundMethodsBlock,
				refundMethodDataBlock: refundMethodDataBlock
			});
		}
	}

	/**
	 * Build refund method data block
	 * @param {Object} selectedRefundMethod
	 * @returns {Object} refund method data block
	 */
	static buildRefundMethodDataBlock(selectedRefundMethod) {
		const data = {
			description: selectedRefundMethod?.description
		};

		if (selectedRefundMethod.formFields && selectedRefundMethod.formFields.length) {
			const formFieldFactory = new FormFieldFactory();
			let renderedFormFields = '';

			for (const formField of selectedRefundMethod.formFields) {
				renderedFormFields += formFieldFactory.create(formField);
			}

			data.formFields = renderedFormFields;
		}

		return app.util.renderTemplate(document.getElementById('ranRefundMethodDataBlock').innerHTML, data);
	}

	/**
	 * Build refund method label block
	 * @param {Object} data refund method label data
	 * @returns {Object} refund method label block
	 */
	buildRefundMethodLabelBlock(data) {
		let label = data.label;

		if (data.additionalInfo) {
			let additionalInfo = data.additionalInfo.label;

			if (data.additionalInfo.url) {
				additionalInfo = app.util.renderTemplate(
					document.getElementById('ranRefundMethodAdditionalInfoBlock').innerHTML,
					data.additionalInfo
				);
			}

			label = app.util.renderTemplate(document.getElementById('ranRefundMethodLabelWithAdditionalInfoBlock').innerHTML, {
				label: label,
				additionalInfoBlock: additionalInfo
			});
		}

		return label;
	}

	/**
	 * Build select products block
	 */
	buildSelectProductsBlock() {
		let productTileMarkups = this.getProductTileMarkups(this.stepModel.order.productLineItems);

		this.stepModel.formContent = productTileMarkups;
	}

	/**
	 * Returns product tile markups
	 * @param {Array} productLineItems Product line items
	 * @returns {String} Product tile markups
	 */
	getProductTileMarkups(productLineItems) {
		let productTileMarkups = '';

		for (const productLineItem of productLineItems) {
			const builder = new ProductTileBuilder(productLineItem);
			const director = new ProductTileDirector(builder);

			director.buildReturnAuthorizationProductTile();

			productTileMarkups += new ProductTile(document.getElementById('ranProductTile').innerHTML, builder.getResult());
		}

		return productTileMarkups;
	}

	/**
	 * Returns refund payment review markup
	 * @param {Object} model
	 * @returns {String} refund payment review markup
	 */
	getRefundPaymentReviewMarkup(model) {
		if (model.formFields && model.formFields.length) {
			let reviewFormFields = '';

			for (const formField of model.formFields) {
				reviewFormFields += app.util.renderTemplate(
					document.getElementById('ranReviewFormFieldBlock').innerHTML,
					formField
				);
			}

			model.content = reviewFormFields;
		}

		return app.util.renderTemplate(document.getElementById('ranReviewRefundMethodBlock').innerHTML, model);
	}

	/**
	 * Build review block
	 */
	buildReviewBlock() {
		let reviewBlockMarkups = '';

		for (const dependentStep of this.stepModel.dependentSteps) {
			switch (dependentStep.stepName) {
				case 'SelectProductsStep':
					dependentStep.reviewBlockContent = this.getProductTileMarkups(dependentStep.selectedProductLineItems);
					break;
				case 'SelectRefundMethodStep':
					dependentStep.reviewBlockContent = this.getRefundPaymentReviewMarkup(dependentStep.selectedRefundPayment);
					break;
				default:
					dependentStep.reviewBlockContent = '';
					break;
			}

			reviewBlockMarkups += app.util.renderTemplate(document.getElementById('ranReviewBlock').innerHTML, dependentStep);
		}

		this.stepModel.formContent = reviewBlockMarkups;
	}

	/**
	 * Returns result model
	 * @returns {Object} Result model
	 */
	getResult() {
		return this.stepModel;
	}
}

export default Builder;
