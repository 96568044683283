import Step from 'ran/classes/returnAuthorizationSteps/Step';

/**
 * Represents return confirmation step
 */
class ReturnConfirmationStep extends Step {
	/**
	 * @constructor
	 * @param {Object} data Step data
	 */
	constructor(data) {
		super(data);
		this.templateId = 'ranConfirmationStep';
	}

	/**
	 * Notify subscribers about render step event
	 * @returns {Boolean}
	 */
	notifyRenderStep() {
		return false;
	}
}

export default ReturnConfirmationStep;
