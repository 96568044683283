/**
 * Represents FormFieldFactory
 */
class FormFieldFactory {
	/**
	 * Create Form Field markups
	 * @param {Object} formField Form field
	 * @returns {String} Markup
	 */
	create(formField) {
		let formFieldElement;
		const data = { ...formField };

		switch (formField.type) {
			case 'checkbox': {
				formFieldElement = 'formFieldCheckbox';
				data.checked = formField.checked ? 'checked' : '';
				break;
			}
			case 'select': {
				formFieldElement = 'formFieldSelect';
				let options = '';

				if (Array.isArray(formField.options)) {
					formField.options.forEach((option) => {
						options += app.util.renderTemplate(document.getElementById('formFieldSelectOptions').innerHTML, {
							...option,
							selected: option.selected ? 'selected="selected"' : '',
							disabled: option.disabled ? 'disabled' : ''
						});
					});
				}

				data.options = options;
				break;
			}
			case 'radio': {
				formFieldElement = 'formFieldRadio';
				let radioOptions = '';

				if (Array.isArray(formField.options)) {
					formField.options.forEach((option) => {
						radioOptions += app.util.renderTemplate(document.getElementById('formFieldRadioOptions').innerHTML, {
							...option,
							checked: option.selected ? 'checked="checked"' : ''
						});
					});
				}

				data.options = radioOptions;
				break;
			}
			case 'hidden': {
				formFieldElement = 'formFieldHidden';
				break;
			}
			default: {
				formFieldElement = 'formFieldInput';
				break;
			}
		}

		return app.util.renderTemplate(document.getElementById(formFieldElement).innerHTML, data);
	}
}

export default FormFieldFactory;
