import Step from 'ran/classes/returnAuthorizationSteps/Step';
import FormFieldUtils from 'oneapp/src/utils/FormFieldUtils';

class SelectProductsStep extends Step {
	constructor(data) {
		super(data);

		this.patterns = {
			returnType: /i[0-9]+_returnType$/g,
			isReturn: /i[0-9]+_doReturn$/g
		};

		this.classes = {
			...this.classes,
			stepSubmitButton: 'js-ran-step-submit',
			doReturn: 'js-doReturn',
			returnType: 'js-returnType'
		};
	}

	/**
	 * Prepare step model
	 */
	prepareModel() {
		const selectedProductLineItems = this.getSelectedProductLineItems();
		this.model.submitButtonLabel = this.model.submitButtonLabel || this.model.stepData.submitButton.label;
		this.model.stepData.submitButton.label = String.format(
			this.model.submitButtonLabel,
			selectedProductLineItems.length || ''
		);
	}

	/**
	 * Initialize step related events
	 */
	initializeStepRelatedEvents() {
		app.owlcarousel.init();

		this.processElementsByClassName(this.classes.doReturn, (el) => {
			el.addEventListener('change', () => {
				const countOfSelectedProducts = Array.from(document.getElementsByClassName(this.classes.doReturn)).filter(
					(el) => el.checked
				).length;

				this.processElementsByClassName(this.classes.stepSubmitButton, (el) => {
					this.model.stepData.submitButton.label = String.format(
						this.model.submitButtonLabel,
						countOfSelectedProducts || ''
					);
					el.innerHTML = this.model.stepData.submitButton.label;
				});
			});
		});
	}

	/**
	 * Initialize submit ran form event
	 */
	initializeSubmitRanFormEvent() {
		const currentStep = this;

		this.processElementsByClassName(this.classes.returnAuthorizationForm, (el) =>
			el.addEventListener('submit', function(event) {
				event.preventDefault();

				const form = this;

				if (form.checkValidity()) {
					const parsedFormData = {};
					const formData = new FormData(form);

					for (const formDataPair of formData.entries()) {
						parsedFormData[formDataPair[0]] = formDataPair[1];
					}

					currentStep.storedData = {
						formData: parsedFormData,
						data: {
							isStepFilled: true
						}
					};

					const payload = {
						requestData: {
							formData: formData,
							stepName: currentStep.getStepName()
						},
						storageData: currentStep.storedData
					};

					currentStep.updateModelWithStoredData();

					if (currentStep.isValid()) {
						currentStep.notify('submitStep', payload);
					}
				}
			})
		);
	}

	/**
	 * Update model with stored data
	 */
	updateModelWithStoredData() {
		super.updateModelWithStoredData();

		if (this.storedData?.formData) {
			const formFieldUtils = new FormFieldUtils();

			for (const productLineItem of this.model.order.productLineItems) {
				if (productLineItem.formFields) {
					for (const formField of productLineItem.formFields) {
						const storedFormFieldData = this.storedData.formData[formField.htmlName];

						if (storedFormFieldData) {
							formFieldUtils.setFormFieldValue(formField, storedFormFieldData);
						}
					}
				}
			}
		}
	}

	/**
	 * Step validation
	 * @param {Boolean} setErrorMessage if true, set step error message in case of errors
	 * @returns {Boolean} Is valid
	 */
	isValid(setErrorMessage = true) {
		let isValid = true;

		if (this.storedData && this.storedData.formData) {
			const selectedProductLineItems = this.getSelectedProductLineItems();

			if (selectedProductLineItems.length === 0) {
				setErrorMessage && this.setErrorMessage({ errorMessage: this.model.stepData?.errors?.noProducts });
				isValid = false;
			} else {
				const isReturnTypeMissing = !!selectedProductLineItems.find(
					(selectedProductLineItem) => selectedProductLineItem.returnType === undefined
				);

				if (isReturnTypeMissing) {
					setErrorMessage && this.setErrorMessage({ errorMessage: this.model.stepData?.errors?.reasonMissing });
					isValid = false;
				}
			}
		}

		return isValid;
	}

	/**
	 * Returns selected product line items
	 * @returns {Array}  selected product line items
	 */
	getSelectedProductLineItems() {
		let selectedProductLineItems = [];

		const getFormLineItemIndex = (htmlName) => {
			const matchResult = htmlName.match(/[0-9]+/g);

			if (matchResult) {
				return matchResult[0];
			}

			return null;
		};

		const processLineItem = (formField, pattern, htmlName) => {
			const lineItemIndex = getFormLineItemIndex(htmlName);

			if (lineItemIndex) {
				const productLineItem = this.model.order.productLineItems[parseInt(lineItemIndex)];
				let selectedProductLineItem = getProductLineItemByUUID(selectedProductLineItems, productLineItem.uuid);
				let isProductLineItemFound = false;

				if (selectedProductLineItem) {
					isProductLineItemFound = true;
				} else {
					selectedProductLineItem = {
						uuid: productLineItem.uuid
					};
				}

				selectedProductLineItem[pattern] = this.storedData.formData[formField];

				if (!isProductLineItemFound) {
					selectedProductLineItems.push(selectedProductLineItem);
				}
			}
		};

		if (this?.storedData?.formData) {
			for (const formField of Object.keys(this.storedData.formData)) {
				for (const pattern of Object.keys(this.patterns)) {
					const matchResult = formField.match(this.patterns[pattern]);

					if (matchResult) {
						processLineItem(formField, pattern, matchResult[0]);
					}
				}
			}
		}

		return selectedProductLineItems.filter((selectedProductLineItem) => selectedProductLineItem.isReturn);
	}

	/**
	 * Returns Step review model
	 * @returns {Object} Step review model
	 */
	getReviewModel() {
		const extentProductLineItemByReturnReasonMessage = (productLineItem, formFields, returnType) => {
			const returnTypeField = formFields.find((formField) => {
				return formField.formId === 'returnType';
			});

			if (returnTypeField && returnTypeField.options) {
				const selectedOption = returnTypeField.options.find((option) => {
					return option.value === returnType;
				});

				if (selectedOption) {
					productLineItem['returnReasonMessage'] = selectedOption.label;
				}
			}
		};

		const selectedProductLineItems = this.getSelectedProductLineItems();
		const reviewProductLineItems = selectedProductLineItems.map((selectedProductLineItem) => {
			const productLineItem = getProductLineItemByUUID(this.model.order.productLineItems, selectedProductLineItem.uuid);

			if (productLineItem) {
				const productLineItemReviewFields = {
					pid: productLineItem.pid,
					image: productLineItem.image,
					name: productLineItem.name,
					prices: productLineItem.prices,
					size: productLineItem.size,
					color: productLineItem.color,
					number: productLineItem.number,
					isReviewMode: true
				};

				extentProductLineItemByReturnReasonMessage(
					productLineItemReviewFields,
					productLineItem.formFields,
					selectedProductLineItem.returnType
				);

				Object.assign(selectedProductLineItem, productLineItemReviewFields);
			}

			return selectedProductLineItem;
		});

		return {
			stepName: this.getStepName(),
			stepId: this.getStepId().toString(),
			header: this.model.stepData.reviewHeader,
			selectedProductLineItems: reviewProductLineItems
		};
	}
}

/**
 * Return product line item by UUID
 * @param {Array} productLineItems
 * @param {String} uuid
 * @returns {Object} product line item
 */
const getProductLineItemByUUID = (productLineItems, uuid) =>
	productLineItems.find((productLineItem) => productLineItem.uuid === uuid);

export default SelectProductsStep;
