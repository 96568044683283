import ProductTileDirector from 'oneapp/src/classes/productTile/Director';

/**
 * Represents return authorization product tile director
 */
class ReturnAuthorizationDirector extends ProductTileDirector {
	/**
	 * Build return authorization product tile
	 */
	buildReturnAuthorizationProductTile() {
		this.builder.setProductAdditionalClasses();
		this.builder.buildNameBlock();
		this.builder.buildSizeBlock();
		this.builder.buildImageBlock();
		this.builder.buildReturnBlock();
		this.builder.buildQuantityBlock();
		this.builder.buildPriceBlock();
	}
}

export default ReturnAuthorizationDirector;
