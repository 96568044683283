import ProductTileBuilder from 'oneapp/src/classes/productTile/Builder';
import FormFieldFactory from 'oneapp/src/utils/FormFieldFactory';

/**
 * Represents return authorization product tile builder
 */
class ReturnAuthorizationProductTileBuilder extends ProductTileBuilder {
	/**
	 * Build quantity block
	 */
	buildQuantityBlock() {
		this.productTile.quantityBlock = this.productTile.qty
			? app.util.renderTemplate(document.getElementById('ranProductTileQuantityBlock').innerHTML, {
				qty: this.productTile.qty
			})
			: '';
	}

	/**
	 * Build return block
	 */
	buildReturnBlock() {
		if (this.productTile.isReviewMode) {
			this.productTile.returnBlock = app.util.renderTemplate(
				document.getElementById('ranProductTileReviewBlock').innerHTML,
				this.productTile
			);
		} else if (this.productTile.isReturnable) {
			const formFieldFactory = new FormFieldFactory();
			let renderedFormFields = '';

			for (const formField of this.productTile.formFields) {
				renderedFormFields += formFieldFactory.create(formField);
			}

			this.productTile.returnBlock = renderedFormFields;
		} else {
			this.productTile.returnBlock = this.productTile.notReturnableMessage;
		}
	}
}

export default ReturnAuthorizationProductTileBuilder;
