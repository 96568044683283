import ReturnAuthorizationData from '../classes/ReturnAuthorizationData';

/**
 * Represents ReturnAuthorizationDataMgr
 */
class ReturnAuthorizationDataMgr {
	/**
	 * Set Return Authorization Data to session storage
	 * @param {String} orderNo
	 * @param {ReturnAuthorizationData} returnAuthorizationData
	 */
	set(orderNo, returnAuthorizationData) {
		sessionStorage.setItem(ReturnAuthorizationData.getKey(orderNo), returnAuthorizationData.toJSON());
	}

	/**
	 * Get return authorization data for order number from session storage
	 * @param {String} orderNo
	 * @returns {ReturnAuthorizationData}
	 */
	get(orderNo) {
		const returnAuthorizationData = new ReturnAuthorizationData();

		try {
			const raObjectString = sessionStorage.getItem(ReturnAuthorizationData.getKey(orderNo));

			if (raObjectString) {
				const raObject = JSON.parse(raObjectString);

				returnAuthorizationData.update(raObject);
			}
		} catch (e) {
			this.set(orderNo, returnAuthorizationData);
		}

		return returnAuthorizationData;
	}

	/**
	 * Update return authorization data for order number in session storage
	 * @param {String} orderNo
	 * @param {Object} data
	 */
	update(orderNo, data) {
		let returnAuthorizationData = this.get(orderNo);

		if (returnAuthorizationData) {
			returnAuthorizationData.update(data);
		} else {
			returnAuthorizationData = new ReturnAuthorizationData(data);
		}

		this.set(orderNo, returnAuthorizationData);
	}

	/**
	 * Remove return authorization data for order number from session storage
	 * @param {*} orderNo
	 */
	remove(orderNo) {
		sessionStorage.removeItem(ReturnAuthorizationData.getKey(orderNo));
	}
}

export default ReturnAuthorizationDataMgr;
