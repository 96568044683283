import SelectProductsStep from './steps/SelectProductsStep';
import ReturnItemsStep from './steps/ReturnItemsStep';
import SelectRefundMethodStep from './steps/SelectRefundMethodStep';
import ReviewStep from './steps/ReviewStep';
import ReturnConfirmationStep from './steps/ReturnConfirmationStep';

/**
 * Represents return authorization steps factory
 */
class StepsFactory {
	/**
	 * @constructor
	 */
	constructor() {
		this.steps = {
			SelectProductsStep,
			ReturnItemsStep,
			SelectRefundMethodStep,
			ReviewStep,
			ReturnConfirmationStep
		};
	}

	/**
	 * Create new return authorization step
	 * @param {String} type Step type
	 * @param {Object} attributes Step attributes
	 * @returns {Step} Return authorization step
	 */
	create(type, attributes) {
		const Step = this.steps[type];

		return new Step(attributes);
	}
}

export default StepsFactory;
