const SESSION_OBJECT_KEY = 'raObjectFor';

/**
 * Represents return authorization data
 */
class ReturnAuthorizationData {
	constructor(returnAuthorizationObject = {}) {
		this.returnAuthorizationObject = returnAuthorizationObject;
	}

	/**
	 * Returns model
	 * @returns {Object} model
	 */
	getModel() {
		return this.returnAuthorizationObject;
	}

	/**
	 * Returns all form data
	 * @returns {FormData}
	 */
	getFormData() {
		const formData = new FormData();

		if (this?.returnAuthorizationObject?.steps) {
			for (const step of Object.keys(this.returnAuthorizationObject.steps)) {
				const currentStep = this.returnAuthorizationObject.steps[step];

				if (currentStep && currentStep.formData) {
					for (const formFieldKey of Object.keys(currentStep.formData)) {
						formData.append(formFieldKey, currentStep.formData[formFieldKey]);
					}
				}
			}
		}

		return formData;
	}

	/**
	 * Update return authorization object
	 * @param {Object} data
	 */
	update(data) {
		if (this?.returnAuthorizationObject?.steps) {
			for (const stepName of Object.keys(data.steps)) {
				for (const field of Object.keys(data.steps[stepName])) {
					if (!this.returnAuthorizationObject.steps[stepName]) {
						this.returnAuthorizationObject.steps[stepName] = {};
					}

					this.returnAuthorizationObject.steps[stepName][field] = data.steps[stepName][field];
				}
			}
		} else {
			this.returnAuthorizationObject = data;
		}
	}

	/**
	 * Returns return authorization object as a stringified JSON
	 * @returns {String}
	 */
	toJSON() {
		return JSON.stringify(this.returnAuthorizationObject);
	}

	/**
	 * Returns key for storage
	 * @param {String} orderNo Order number
	 * @returns {String}
	 */
	static getKey(orderNo) {
		return `${SESSION_OBJECT_KEY}${orderNo}`;
	}
}

export default ReturnAuthorizationData;
