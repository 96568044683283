/**
 * Represents return authorization step markup director
 */
class Director {
	/**
	 * @constructor
	 * @param {Builder} builder
	 */
	constructor(builder) {
		this.builder = builder;
	}

	/**
	 * Build general step markup
	 */
	buildGeneralStepMarkup() {
		this.builder.buildComeToBackBlock();
		this.builder.buildProgressWidgetBlock();
	}

	/**
	 * Build markup for Select Products Step
	 */
	buildSelectProductsStepMarkup() {
		this.buildGeneralStepMarkup();
		this.builder.buildSelectProductsBlock();
	}

	/*
	 * Build markup for Select Refund Method Step
	 */
	buildSelectRefundMethodStepMarkup() {
		this.buildGeneralStepMarkup();
		this.builder.buildRefundMethodBlock();
	}

	/**
	 * Build markup for Review Step
	 */
	buildReviewStepMarkup() {
		this.buildGeneralStepMarkup();
		this.builder.buildReviewBlock();
	}

	/**
	 * Build step markup by step name
	 * @param {String} stepName
	 */
	buildStepMarkup(stepName) {
		switch (stepName) {
			case 'ReturnConfirmationStep':
				break;
			default: {
				const buildStepMarkupMethod = this[`build${stepName}Markup`];

				if (buildStepMarkupMethod && typeof buildStepMarkupMethod === 'function') {
					buildStepMarkupMethod.call(this);
				} else {
					this.buildGeneralStepMarkup();
				}

				break;
			}
		}
	}
}

export default Director;
