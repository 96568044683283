/**
 * Represents logic and popups for locked pages
 */
class LockedAreaAuthorization {
	constructor(delay, popupVariant = 'primary') {
		this.delay = delay;
		this.variant = popupVariant;
	}

	init() {
		if (document.getElementsByClassName('js-locked-page-container').length) {
			const self = this;

			setTimeout(() => {
				app.popupsMgr.open('LoginPopup', {
					templateId: 'loginPopupTemplate',
					variant: self.variant
				});
			}, this.delay);
		}
	}
}

export default LockedAreaAuthorization;
