import Observable from 'oneapp/src/classes/observable';
import FormFieldUtils from 'oneapp/src/utils/FormFieldUtils';
import StepMarkupBuilder from 'ran/classes/returnAuthorizationStepMarkup/Builder';
import StepMarkupDirector from 'ran/classes/returnAuthorizationStepMarkup/Director';
import StepMarkup from 'ran/classes/returnAuthorizationStepMarkup/StepMarkup';

/**
 * Represents return authorization step
 */
class Step extends Observable {
	/**
	 * @constructor
	 * @param {Object} data Step data
	 */
	constructor(data) {
		super();

		this.stepName = data.stepName;
		this.stepId = data.stepId;
		this.model = data.model;
		this.globalData = data.global;
		this.storedData = data.storedData || {};

		this.isStepFilled = false;
		this.errorMessage = '';
		this.templateId = 'ranGeneralStep';

		this.events = {
			renderStep: 'renderStep',
			submitStep: 'submitStep',
			activatePreviousStep: 'activatePreviousStep',
			activateSelectedStep: 'activateSelectedStep'
		};
		this.classes = {
			stepErrorMessage: 'js-ran-step-error-message',
			returnAuthorizationForm: 'js-ran-form',
			comeToBackButton: 'js-ran-come-to-back',
			stepWrapper: data.stepWrapper
		};

		this.updateModelWithStoredData();
	}

	/**
	 * Returns step id
	 * @returns {Number} Step id
	 */
	getStepId() {
		return this.stepId;
	}

	/**
	 * Returns step name
	 * @returns {String} Step name
	 */
	getStepName() {
		return this.stepName;
	}

	/**
	 * Returns step model
	 * @returns {Object} Step model
	 */
	getStepModel() {
		return this.model;
	}

	/**
	 * Returns Step review model
	 * @returns {Object} Step review model
	 */
	getReviewModel() {
		return {};
	}

	/**
	 * Set step error message
	 * @param {String} errorMessage
	 */
	setErrorMessage(data) {
		if (data.errorMessage) {
			this.processElementsByClassName(this.classes.stepErrorMessage, (el) => (el.innerHTML = data.errorMessage));
		}

		if (data.invalidForm && data.fields?.length) {
			const formFieldUtils = new FormFieldUtils();

			for (const formField of data.fields) {
				formFieldUtils.setErrorMessage(formField.htmlName, formField.error);
			}
		}
	}

	/**
	 * Update model with stored data
	 */
	updateModelWithStoredData() {
		this.isStepFilled = !!this.storedData?.data?.isStepFilled;
	}

	/**
	 * Step validation
	 * @param {Boolean} setErrorMessage if true, set step error message in case of errors
	 * @returns {Boolean} Is valid
	 */

	isValid(setErrorMessage = true) {
		return true;
	}

	/**
	 * Checks if step is filled
	 * @returns {Boolean} Is filled
	 */
	isFilled() {
		return this.isStepFilled && this.isValid(false);
	}

	/**
	 * Render step
	 */
	render() {
		this.prepareModel();
		this.notifyRenderStep();
		this.renderDOM();
		this.initializeEvents();
	}

	/**
	 * Prepare step model
	 */
	prepareModel() {}

	/**
	 * Notify subscribers about render step event
	 */
	notifyRenderStep() {
		this.notify(this.events.renderStep, {
			requestData: {
				stepName: this.getStepName()
			},
			storageData: {
				data: {
					isStepFilled: false
				}
			}
		});
	}

	/**
	 * Render DOM
	 */
	renderDOM() {
		const builder = new StepMarkupBuilder(this.getStepName(), this.model, this.globalData);
		const director = new StepMarkupDirector(builder);
		director.buildStepMarkup(this.getStepName());
		const stepMarkup = new StepMarkup(document.getElementById(this.templateId).innerHTML, builder.getResult());

		this.processElementsByClassName(this.classes.stepWrapper, (el) => (el.innerHTML = stepMarkup));
	}

	/**
	 * Initialize step events
	 */
	initializeEvents() {
		app.validator.init();

		this.initializeComeToBackEvent();
		this.initializeSubmitRanFormEvent();
		this.initializeStepRelatedEvents();
	}

	/**
	 * Initialize submit ran form event
	 */
	initializeSubmitRanFormEvent() {
		const currentStep = this;

		this.processElementsByClassName(this.classes.returnAuthorizationForm, (el) =>
			el.addEventListener('submit', function(event) {
				event.preventDefault();

				currentStep.storedData = {
					data: {
						isStepFilled: true
					}
				};

				const payload = {
					requestData: {
						stepName: currentStep.getStepName()
					},
					storageData: currentStep.storedData
				};

				currentStep.updateModelWithStoredData();
				currentStep.notify(currentStep.events.submitStep, payload);
			})
		);
	}

	/**
	 * Initialize come to back event
	 */
	initializeComeToBackEvent() {
		this.processElementsByClassName(this.classes.comeToBackButton, (el) =>
			el.addEventListener('click', () => this.notify(this.events.activatePreviousStep))
		);
	}

	/**
	 * Initialize step related events
	 */
	initializeStepRelatedEvents() {}

	/**
	 * Set dependent steps
	 * @param {Array} dependentSteps
	 */
	setDependentSteps(dependentSteps) {
		this.dependentSteps = dependentSteps.reverse();
	}

	/**
	 * Process elements by class name
	 * @param {String} className
	 * @param {Function} callBack
	 */
	processElementsByClassName(className, callBack) {
		for (const element of Array.from(document.getElementsByClassName(className))) {
			callBack(element);
		}
	}
}

export default Step;
