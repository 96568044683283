import Step from 'ran/classes/returnAuthorizationSteps/Step';

/**
 * Represents review step
 */
class ReviewStep extends Step {
	/**
	 * @constructor
	 * @param {Object} data Step data
	 */
	constructor(data) {
		super(data);
		this.classes = {
			...this.classes,
			modifyStepButton: 'js-ran-modify-step'
		};
		this.events = {
			...this.events,
			submitReturnAuthorization: 'submitReturnAuthorization'
		};
	}

	/**
	 * Prepare step model
	 */
	prepareModel() {
		if (this.dependentSteps && Array.isArray(this.dependentSteps)) {
			this.model.dependentSteps = this.dependentSteps.map((dependentStep) => dependentStep.getReviewModel());
		}
	}

	/**
	 * Initialize step related events
	 */
	initializeStepRelatedEvents() {
		this.processElementsByClassName(this.classes.modifyStepButton, (el) => {
			el.addEventListener('click', (event) => {
				event.preventDefault();

				this.notify(this.events.activateSelectedStep, { stepId: event.target.dataset.stepId });
			});
		});
	}

	/**
	 * Initialize submit ran form event
	 */
	initializeSubmitRanFormEvent() {
		this.processElementsByClassName(this.classes.returnAuthorizationForm, (el) => {
			el.addEventListener('submit', (event) => {
				event.preventDefault();

				this.notify(this.events.submitReturnAuthorization);
			});
		});
	}
}

export default ReviewStep;
