import Step from 'ran/classes/returnAuthorizationSteps/Step';
import StepMarkupBuilder from 'ran/classes/returnAuthorizationStepMarkup/Builder';
import FormFieldUtils from 'oneapp/src/utils/FormFieldUtils';

/**
 * Represents return authorization step
 */
class SelectRefundMethodStep extends Step {
	/**
	 * @constructor
	 * @param {Object} data Step data
	 */
	constructor(data) {
		super(data);

		this.auxiliaryFormFields = {
			refundMethod: {
				formId: 'refundMethod',
				htmlName: 'refundMethod',
				type: 'radio'
			}
		};
		this.classes = {
			...this.classes,
			refundMethodData: 'js-ran_refund-method_data',
			refundMethodSection: 'js-ran-step-selectrefundmethodstep',
			refundMethodSingle: 'm-single-refundmethod'
		};
	}

	/**
	 * Prepare step model
	 */
	prepareModel() {
		if (this.model?.refundMethods) {
			this.model.selectedRefundMethod = this.model.refundMethods.find((refundMethod) => refundMethod.isSelected);

			if (this.model.selectedRefundMethod === undefined) {
				this.model.selectedRefundMethod = this.model.refundMethods[0];
				this.model.selectedRefundMethod.isSelected = true;
			}

			const refundMethodRadio = {
				...this.auxiliaryFormFields.refundMethod
			};

			refundMethodRadio.options = this.model.refundMethods.map((refundMethod) => {
				return {
					htmlName: refundMethodRadio.htmlName,
					label: refundMethod.displayName,
					selected: refundMethod.isSelected,
					value: refundMethod.id,
					additionalInfo: refundMethod.additionalInfo,
					id: `${refundMethodRadio.htmlName}-${refundMethod.id}`
				};
			});

			this.model.refundMethodRadio = refundMethodRadio;
		}
	}

	/**
	 * Initialize step related events
	 */
	initializeStepRelatedEvents() {
		for (const el of Array.from(document.getElementsByName(this.auxiliaryFormFields.refundMethod.htmlName))) {
			el.addEventListener('click', (event) => {
				const selectedRefundMethod = this.model.refundMethods.find(
					(refundMethod) => refundMethod.id === event.target.value
				);

				if (selectedRefundMethod !== this.model.selectedRefundMethod) {
					selectedRefundMethod.isSelected = true;
					this.model.selectedRefundMethod.isSelected = false;
					this.model.selectedRefundMethod = selectedRefundMethod;
				}

				this.processElementsByClassName(this.classes.refundMethodData, (el) => {
					const refundMethodDataBlock = StepMarkupBuilder.buildRefundMethodDataBlock(this.model.selectedRefundMethod);
					el.innerHTML = refundMethodDataBlock;
				});
			});
		}

		if (this.model.refundMethods.length === 1) {
			document.getElementsByClassName(this.classes.refundMethodSection)[0].classList.add(this.classes.refundMethodSingle);
		}
	}

	/**
	 * Update model with stored data
	 */
	updateModelWithStoredData() {
		super.updateModelWithStoredData();

		if (this.storedData?.data?.selectedRefundMethodId && this.model?.refundMethods) {
			const formFieldUtils = new FormFieldUtils();

			const selectedRefundMethod = this.model.refundMethods.find(
				(refundMethod) => refundMethod.id === this.storedData.data.selectedRefundMethodId
			);

			if (selectedRefundMethod) {
				this.model.selectedRefundMethod = selectedRefundMethod;
				this.model.selectedRefundMethod.isSelected = true;

				if (this.storedData?.formData && this.model.selectedRefundMethod.formFields) {
					const reviewFormFields = [];

					for (const formField of this.model.selectedRefundMethod.formFields) {
						const storedFormFieldData = this.storedData.formData[formField.htmlName];

						formFieldUtils.setFormFieldValue(formField, storedFormFieldData);
						reviewFormFields.push({ label: formField.label, value: formField.value });
					}

					this.model.selectedRefundMethod.reviewModel.formFields = reviewFormFields;
				}
			}
		}
	}

	/**
	 * Initialize submit ran form event
	 */
	initializeSubmitRanFormEvent() {
		const currentStep = this;

		this.processElementsByClassName(this.classes.returnAuthorizationForm, (el) =>
			el.addEventListener('submit', function(event) {
				event.preventDefault();

				const form = this;

				if (form.checkValidity()) {
					const formData = new FormData(form);
					const parsedFormData = {};

					if (currentStep.model.selectedRefundMethod?.formField?.htmlName) {
						formData.append(currentStep.model.selectedRefundMethod.formField.htmlName, true);
					}

					for (const auxiliaryFormField of Object.keys(currentStep.auxiliaryFormFields)) {
						formData.delete(currentStep.auxiliaryFormFields[auxiliaryFormField].htmlName);
					}

					for (const formDataPair of formData.entries()) {
						parsedFormData[formDataPair[0]] = formDataPair[1];
					}

					currentStep.storedData = {
						formData: parsedFormData,
						data: {
							isStepFilled: true,
							selectedRefundMethodId: currentStep.model.selectedRefundMethod.id
						}
					};

					const payload = {
						requestData: {
							formData: formData,
							stepName: currentStep.getStepName()
						},
						storageData: currentStep.storedData
					};

					currentStep.updateModelWithStoredData();

					if (currentStep.isValid()) {
						currentStep.notify(currentStep.events.submitStep, payload);
					}
				}
			})
		);
	}

	/**
	 * Notify subscribers about render step event
	 */
	notifyRenderStep() {
		this.notify(this.events.renderStep, {
			requestData: {
				stepName: this.getStepName()
			},
			storageData: {
				data: {
					selectedRefundMethodId: this.model.selectedRefundMethod.id,
					isStepFilled: false
				}
			}
		});
	}

	/**
	 * Returns Step review model
	 * @returns {Object} Step review model
	 */
	getReviewModel() {
		return {
			header: this.model?.stepData?.reviewHeader,
			stepId: this.getStepId(),
			stepName: this.getStepName(),
			selectedRefundPayment: this.model.selectedRefundMethod.reviewModel
		};
	}
}

export default SelectRefundMethodStep;
