import ReturnAuthorizationStepsMgr from './ReturnAuthorizationStepsMgr';
import ReturnAuthorizationDataMgr from './ReturnAuthorizationDataMgr';

/**
 * Represents ReturnAuthorizationMgr
 */
class ReturnAuthorizationMgr {
	constructor() {
		this.classes = {
			returnAuthorizationWrapper: 'js-return-auth-wrapper'
		};
	}

	/**
	 * Initialize return authorization manager
	 */
	init() {
		if (document.getElementsByClassName(this.classes.returnAuthorizationWrapper).length > 0) {
			this.returnAuthorizationDataMgr = new ReturnAuthorizationDataMgr();
			this.returnAuthorizationStepsMgr = new ReturnAuthorizationStepsMgr();

			this.returnAuthorizationStepsMgr.subscribe(this);
			this.returnAuthorizationStepsMgr.initialize();
		}
	}

	/**
	 * Process subscriptions notifications
	 * @param {String} updateID
	 * @param {Object} data
	 */
	update(updateID, data) {
		let returnAuthorizationData;

		switch (updateID) {
			case 'storageGet': {
				returnAuthorizationData = this.returnAuthorizationDataMgr.get(data.orderNo);
				this.returnAuthorizationStepsMgr.initializeByStoredData(returnAuthorizationData.getModel());

				break;
			}
			case 'submitReturnAuthorization': {
				returnAuthorizationData = this.returnAuthorizationDataMgr.get(data.orderNo);
				const formData = returnAuthorizationData.getFormData();

				this.returnAuthorizationStepsMgr.submitReturnAuthorization(formData);

				break;
			}
			case 'storageUpdate': {
				this.returnAuthorizationDataMgr.update(data.orderNo, data);

				break;
			}
			case 'storageRemove': {
				this.returnAuthorizationDataMgr.remove(data.orderNo);

				break;
			}
			default:
				break;
		}
	}
}

export default ReturnAuthorizationMgr;
